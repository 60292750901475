<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentProject /> 
</template>
<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/team/dashboard/HeaderDash.vue'
import SideMenu from '@/components/team/dashboard/SideMenu.vue'
import ContentProject from '@/components/team/dashboard/modules/project/ContentProject.vue'

export default 
{
  name: 'Project',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentProject
  }
}
</script>
<style>
* {
    box-sizing: border-box;
}
:root {
    --color-one: #15588E;
    --color-two: #ffffff;
    --color-three: #323232;
    --color-four: #F4F4F4;
    --color-five: #E9E9EB;
    --color-six: #0D3E67;    
} 
body {
  font-size: .875rem;
  background-color: var(--color-five);
}
a{
    text-decoration: none;
}
.body-class{
  font-size: .875rem;
  background-color: var(--color-five);
}
.cursor-pointer{
  cursor: pointer;
}

.bg-white{
  background-color: var(--color-two);
}
.btn-blue{
  background-color: var(--color-six);
  border: none;
}
.btn-blue:hover{
  background-color: var(--color-one);
  border: none;
}
.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
.bg-gray{
  background-color: #bbbbbb;
}
/*
 * Text Styles
*/
.text-blue{
  color: var(--color-one);
} 
.text-dark-blue{
  color: var(--color-six);
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
.m-p-sidebar{
    margin: 20px;
    padding: 5px;
}
.user-options{ 
  font-size: 1.25rem;
    line-height: 1;
    background-color: var(--color-one);
    transition: box-shadow .15s ease-in-out;
    color: var(--color-two);
    border-radius: 100%;
    width: 33px;
    height: 33px;
    padding: 7px 8px;
}
.user-options:hover{ 
  font-size: 1.25rem;
    line-height: 1;
    background-color: var(--color-one);
    transition: box-shadow .15s ease-in-out;
    color: var(--color-two);
    border-radius: 100%;
    width: 33px;
    height: 33px;
    padding: 7px 8px;
}
.user-options:focus{ 
  font-size: 1.25rem;
    line-height: 1;
    background-color: var(--color-one);
    transition: box-shadow .15s ease-in-out;
    color: var(--color-two);
    border-radius: 100%;
    width: 33px;
    height: 33px;
    padding: 7px 8px;
}
.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    font-size: 0.9rem;
    color: var(--color-one) !important;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    border: none;
}
.dropdown-item {    
    color: var(--color-one) !important;   
    background-color: transparent;
    border: 0;
}
.dropdown-item:focus {    
    color: var(--color-one) !important;   
    background-color: transparent;
    border: 0;
}
.margin-r{
  margin-right:3px;
}
.btn-toggle{
  display: inline-flex;
}
.btn-toggle-nav a {
    display: inline-flex;
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
    text-decoration: none;
}
.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba(13,62,103,1)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}
.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}
.button-1 {
    background-color: var(--color-six);
    border: 1px solid var(--color-one);
}

.drop-actions{
  border: 1px solid #C4C4C4;
}
/* search */
.search-box{
  top: 33px;
    position: absolute;
    right: 36px;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 0rem;
  }
  .user-options{ 
    position: absolute;
    right: 5rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: var(--color-one);
    transition: box-shadow .15s ease-in-out;
    color: var(--color-two);
    border-radius: 100%;
    width: 33px;
    height: 33px;
    padding: 7px 8px;
  }
  .user-options:hover, .user-options:focus{
    position: absolute;
    right: 5rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: #0d6efd;
    transition: box-shadow .15s ease-in-out;
    color: white;
    border-radius: 100%;
    width: 33px;
    height: 33px;
    padding: 7px 8px;
  }
  .brand-none{
    display: none !important;
  }
  .option-none{
    display: none !important;
  }
}
.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
.sidebar .nav-link {
  font-weight: 500;
  color: var(--color-six);
}
.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}
.sidebar .nav-link.active {
  color: var(--color-one);
  background-color: var(--color-five);
  width: 100%;
}
.sidebar .nav-link.active {
  color: var(--color-one);
  background-color: var(--color-five);
  width: 100%;
}
.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}
.btn-toggle-2{
  display: inline-flex;
}
.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: var(--color-one)
}
.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}
/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: var(--color-two);
  /*box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);*/
}

.navbar .navbar-toggler { 
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: var(--color-two);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}
/*
* Play Button
*/
.play-button{
    top: 25px;
    position: absolute;
}
.profile-picture{
  border-radius: 100%;
}

</style>